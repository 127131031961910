import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";

import "../components/style.scss";

const photography = [
  {
    title: "Event Photography",
    description:
      "I like to focus on capturing the atmosphere of the location, the details of the day and, most importantly, the people and their interactions. Together, these factors create great photos that will help you to emphasize brand awareness and your unique identity."
  },
  {
    title: "Corporate Headshots",
    description:
      "In today's online market place your corporate headshot or business portrait is often the first impression you'll make on a potential client. It's an integral part of your business brand and is crucial in conveying confidence, trust and a sense of familiarity."
  },
  {
    title: "Creative Portraits",
    description:
      "Ready for having some fun while developing concepts? More elaborated, editorial-style projects or portraits are also available as per request basis. This is a great opportunity to expand your creativity in a more relaxed format."
  }
];

export default () => (
  <StaticQuery
    query={graphql`
      query customerImagesQuery {
        customerAssets: allFile(
          filter: { absolutePath: { regex: "/customers/" } }
        ) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 700) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <div>
          <section className="section">
            <div className="container">
              <div className="columns is-multiline">
                {photography.map(item => (
                  <div className="column is-4 content is-small">
                    <h1 className="title is-4">{item.title}</h1>
                    <p>{item.description}</p>
                  </div>
                ))}
              </div>
            </div>
          </section>
          <section className="section">
            <div className="container">
              <div className="columns is-multiline">
                {shuffle(data.customerAssets.edges).map(item => (
                  <div className="column is-2">
                    <Img fluid={item.node.childImageSharp.fluid}  />
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )}
  />
)

function shuffle(arra1) {
    let ctr = arra1.length;
    let temp;
    let index;

    // While there are elements in the array
    while (ctr > 0) {
// Pick a random index
        index = Math.floor(Math.random() * ctr);
// Decrease ctr by 1
        ctr--;
// And swap the last element with it
        temp = arra1[ctr];
        arra1[ctr] = arra1[index];
        arra1[index] = temp;
    }
    return arra1;
}
